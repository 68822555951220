import styled from 'styled-components';
import helpers, { HelperProps } from '../helpers';

export type TileProps = HelperProps & {
  full?: boolean;
  variant?: 'flat' | 'raised' | 'x-Large' | 'resting' | 'no-shadow' | 'card';
  hoverVariant?: 'card-hover';
  width?: string;
};

const getShadowVariant = (variant?: TileProps['variant'] | TileProps['hoverVariant']): string => {
  if (variant === 'raised') return '10px 15px 80px 0 rgba(0,0,0,0.1);';
  if (variant === 'x-Large') return '0 96px 126px 0 rgba(0,0,0,0.26);';
  if (variant === 'resting') return '0 0 4px 0 rgba(0,0,0,0.1);';
  if (variant === 'card') return '0 0 4px 2px rgba(0,0,0,0.1);';
  if (variant === 'card-hover') return '5px 10px 20px 0 rgba(0,0,0,0.1);';
  if (variant === 'no-shadow') return 'initial;';

  return '1px 1px 1px 1px rgba(0,0,0,0.01);';
};

const Tile = styled.div<TileProps>`
  padding: ${(props) => props.theme.spacing.sp24};
  box-shadow: ${(props) => getShadowVariant(props.variant)};
  background-color: ${(props) => props.theme.colors.background};
  box-sizing: border-box;
  transition: box-shadow 0.2s ease;
  ${(props) => (props.full ? 'width: 100%; height: 100%;' : '')}
  ${(props) => (props.width ? `width: ${props.width};` : '')}


  &:hover {
    ${(props) => (props.hoverVariant ? `box-shadow: ${getShadowVariant(props.hoverVariant)};` : ``)}
  }

  &:active,
  &:focus {
    transition: box-shadow 0.03s ease;
    ${(props) => (props.hoverVariant ? `box-shadow: ${getShadowVariant(props.variant)};` : ``)}
  }

  ${helpers};
`;

export default Tile;
