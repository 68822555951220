import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgGovermentResources(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={94} height={103} viewBox="0 0 96 96">
        <g strokeWidth="3" fill="none" fillRule="evenodd" strokeLinejoin="round">
          <path
            className="strokePrimary"
            d="M40.523 1C36.68 6.476 11.058 17.7 1 17.7v39.664C1 76.012 38.305 96.41 40.5 97 42.695 96.411 80 74.586 80 59.451v-41.75C69.027 17.7 44.363 6.475 40.523 1z"
          />
          <g className="strokeSecondary">
            <path d="M41 46.957V72 46.957zM66 36.522v25.043L41 72 16 61.565V36.522l25 10.476z" />
            <path d="M66 36.522L41 46.957 16 36.522v-2.087L41 24l25 10.435zM55.51 40.9L28.565 29.19 55.51 40.9z" />
          </g>
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgGovermentResources;
