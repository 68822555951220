import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgLocalization(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g fill="none" fillRule="evenodd">
          <path
            d="M40.652 69.87V97 69.87zM51.799 41.75l26.418 11.424v27.13L39.61 97 1 80.304v-27.13L27.419 41.75l12.19-5.406z"
            className="strokeSecondary"
            strokeWidth="3"
            strokeLinejoin="round"
          />
          <path
            className="strokeSecondary"
            strokeWidth="3"
            strokeLinejoin="round"
            d="M78.217 53.174L39.61 69.87 1 53.174l38.609-16.892z"
          />
          <path
            d="M40.652 53.174c4.72-15.715 18.783-18.975 18.783-33.391C59.435 9.675 50.448 1 40.652 1 30.856 1 21.87 9.675 21.87 19.783c0 14.416 14.061 17.676 18.782 33.39z"
            fill="#FFF"
          />
          <path
            d="M71.957 71.957v4.173-4.173zM65.696 74.043v4.174-4.174z"
            className="strokeSecondary"
            strokeWidth="3"
            strokeLinejoin="round"
          />
          <g className="strokePrimary" strokeLinejoin="round" strokeWidth="3">
            <path d="M40.652 53.174c4.72-15.715 18.783-18.975 18.783-33.391C59.435 9.675 50.448 1 40.652 1 30.856 1 21.87 9.675 21.87 19.783c0 14.416 14.061 17.676 18.782 33.39z" />
            <path d="M40.677 13.522c3.473 0 6.288 2.792 6.288 6.236 0 3.441-2.815 6.233-6.288 6.233-3.47 0-6.286-2.792-6.286-6.233 0-3.444 2.816-6.236 6.286-6.236z" />
          </g>
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgLocalization;
