import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgCovidResources(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g fill="none" strokeWidth="3" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
          <path
            className="strokePrimary"
            d="M42.229 2C46.2 7.475 71.107 17.628 82.457 17.628v42.419C82.457 75.18 44.497 97.41 42.23 98 39.96 97.411 2 75.18 2 60.047v-42.42C13.35 17.628 38.256 7.476 42.229 2z"
          />
          <g className="strokeSecondary">
            <path d="M55.886 60.953a18.018 18.018 0 01-2.575 2.249l2.575-2.249zM60.837 49.477a18.061 18.061 0 01-1.196 5.546l1.196-5.546zM49.249 31.73a18.309 18.309 0 00-5.733-1.2l5.733 1.2zM56.623 37.456a17.843 17.843 0 00-2.023-2.298l2.023 2.298zM35.088 65.715a18.276 18.276 0 005.733 1.2l-5.733-1.2zM26.971 59.545a18.119 18.119 0 002.578 2.812l-2.578-2.812zM28.264 38.08l-8.838-3.843 8.838 3.844zM23.497 47.965c.07-1.264.272-2.508.597-3.713l-.597 3.713z" />
            <path d="M20.488 32.033L18.26 36.66l2.228-4.626zM29.098 52.593l-10.726 3.311 10.726-3.311zM17.543 53.388l1.488 4.52-1.488-4.52zM33.107 44.087v2.187-2.187zM37.327 56.694v2.187-2.187zM40.493 47.237v2.19-2.19zM47.876 39.883v2.19-2.19zM48.932 50.39v2.189-2.19zM45.556 61.562l4.16 10.385-4.16-10.385zM47.45 72.89l4.407-1.833-4.407 1.833zM55.796 44.288l10.84-2.923-10.84 2.923z" />
            <path d="M67.214 43.362L65.89 38.79l1.323 4.572zM37.91 36.41l-2.435-10.915 2.434 10.915z" />
            <path d="M37.775 24.948l-4.645 1.105 4.645-1.105zM35.088 65.715l-3.55 9.078 3.55-9.078zM29.358 73.855l4.265 1.833-4.265-1.833zM59.64 55.023l8.871 3.512-8.87-3.512zM67.606 60.502l1.925-4.185-1.925 4.185zM49.249 31.73l3.67-8.124-3.67 8.123zM54.415 24.144L51.32 23.03l3.096 1.115z" />
          </g>
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgCovidResources;
