import { useMemo } from 'react';
import env from '../env';
import { buildUrl } from './urlUtils';

export const useMediaUrl = (path?: string | null) =>
  useMemo(() => {
    if (!path) return undefined;
    try {
      return new URL(path).toString();
    } catch (_) {
      return buildUrl(path, env().NEXT_PUBLIC_MEDIA_URI);
    }
  }, [path]);
