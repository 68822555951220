import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgShipping(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g transform="translate(0 13)" fill="none" fillRule="evenodd">
          <circle className="fillLightGrey" fillRule="nonzero" cx={38.228} cy={60.11} r={2.423} />
          <circle className="fillLightGrey" fillRule="nonzero" cx={79.425} cy={60.11} r={2.423} />
          <circle className="fillWhite" fillRule="nonzero" cx={26.397} cy={16.467} r={14.81} />
          <path
            d="M5.63 28.884h4.81a1.535 1.535 0 100-3.07H5.63a1.535 1.535 0 100 3.07zM18.627 43.212H7.677a1.535 1.535 0 100 3.07h10.95a1.535 1.535 0 100-3.07zM1.535 37.071h12.998a1.535 1.535 0 100-3.07H1.535a1.535 1.535 0 100 3.07zM14.533 55.494h-1.259a1.535 1.535 0 100 3.07h1.26a1.535 1.535 0 100-3.07zM8.157 55.494H1.535a1.535 1.535 0 100 3.07h6.622a1.535 1.535 0 100-3.07zM80.207 16.712H67.194v-2.764c0-.848-.687-1.535-1.535-1.535H46.128a1.535 1.535 0 100 3.07h17.996v32.343a1.535 1.535 0 103.07 0V19.782h13.013c7.015 0 12.722 5.706 12.722 12.72v25.97h-3.903c-.739-4.635-4.762-8.189-9.601-8.189-4.84 0-8.863 3.554-9.601 8.188h-2.63v-5.527a1.535 1.535 0 10-3.07 0v5.527H47.83c-.739-4.634-4.762-8.188-9.601-8.188-4.84 0-8.863 3.554-9.601 8.188h-3.194v-22.39a1.535 1.535 0 10-3.07 0v23.925c0 .848.687 1.536 1.535 1.536h4.728c.739 4.634 4.762 8.188 9.601 8.188 4.84 0 8.863-3.554 9.601-8.188h21.994c.739 4.634 4.762 8.188 9.601 8.188 4.84 0 8.863-3.554 9.601-8.188h5.439c.848 0 1.535-.688 1.535-1.536V32.502c.001-8.707-7.084-15.79-15.792-15.79zM38.228 66.659a6.66 6.66 0 01-6.652-6.653 6.66 6.66 0 016.652-6.653 6.66 6.66 0 016.653 6.653 6.66 6.66 0 01-6.653 6.653zm41.197 0a6.66 6.66 0 01-6.653-6.65v-.003-.002a6.66 6.66 0 016.653-6.65 6.66 6.66 0 016.653 6.652 6.66 6.66 0 01-6.653 6.653z"
            className="fillSecondary"
          />
          <path
            d="M89.86 32.502c0-5.32-4.33-9.65-9.653-9.65H71.8c-.848 0-1.535.688-1.535 1.536V38.82c0 .847.687 1.535 1.535 1.535h16.524c.848 0 1.536-.688 1.536-1.535v-6.318zm-3.072 4.783H73.335V25.924h6.872a6.588 6.588 0 016.581 6.579v4.782zM38.228 56.048a3.963 3.963 0 00-3.958 3.959 3.963 3.963 0 003.958 3.958 3.963 3.963 0 003.959-3.958 3.963 3.963 0 00-3.959-3.959zm0 4.846a.889.889 0 010-1.775.889.889 0 010 1.775zM79.425 56.048a3.963 3.963 0 00-3.958 3.959 3.963 3.963 0 003.958 3.958 3.963 3.963 0 003.958-3.958 3.963 3.963 0 00-3.958-3.959zm0 4.846a.889.889 0 010-1.775.888.888 0 010 1.775zM88.324 44.551c-.848 0-1.535.688-1.535 1.536v3.889a1.535 1.535 0 103.07 0v-3.89c0-.847-.687-1.535-1.535-1.535z"
            className="fillSecondary"
          />
          <path
            d="M26.397 32.71c9.012 0 16.344-7.333 16.344-16.345C42.741 7.352 35.41.02 26.397.02c-2.071 0-4.09.382-6 1.137a1.535 1.535 0 101.127 2.856 13.21 13.21 0 014.873-.923c7.32 0 13.274 5.955 13.274 13.275s-5.955 13.274-13.274 13.274c-7.32 0-13.275-5.955-13.275-13.274 0-3.724 1.579-7.299 4.331-9.81a1.535 1.535 0 10-2.07-2.268 16.386 16.386 0 00-5.331 12.078c0 9.012 7.332 16.344 16.345 16.344z"
            className="fillPrimary"
          />
          <path
            d="M26.397 6.282c-.848 0-1.536.687-1.536 1.535v8.547c0 .848.688 1.536 1.536 1.536h3.791a1.535 1.535 0 100-3.07h-2.256V7.816c0-.848-.687-1.535-1.535-1.535z"
            className="fillPrimary"
          />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgShipping;
