import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgCommunications(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g fill="none" fillRule="evenodd">
          <g className="fillSecondary">
            <path d="M93.477 58.418h.014-.014zm-13.86 5.344a1.3 1.3 0 01-.442-.976v-50.29c0-.65.484-1.202 1.13-1.287 10.08-1.309 12.514-2.8 13.04-3.253C91.345 5.863 74.65 2.985 48.04 2.985S4.734 5.863 2.736 7.955C3.26 8.41 5.695 9.9 15.775 11.21c.646.085 1.13.636 1.13 1.287v50.29a1.3 1.3 0 01-1.465 1.287C1.87 62.31.04 60.147.04 58.263c0-2.017 2.566-3.066 4.803-3.755a1.296 1.296 0 011.621.859 1.3 1.3 0 01-.857 1.621c-1.842.567-2.577 1.04-2.853 1.273.536.449 2.81 1.798 11.556 3.04V13.63C1.767 11.898.04 9.804.04 7.974.04.476 46.08.39 48.04.39c1.96 0 48 .086 48 7.583 0 1.83-1.727 3.924-14.27 5.659V61.3c8.745-1.242 11.019-2.591 11.556-3.04-.276-.232-1.012-.706-2.857-1.274a1.297 1.297 0 01.765-2.48c2.237.69 4.806 1.74 4.806 3.755 0 1.885-1.83 4.048-15.4 5.811a1.296 1.296 0 01-1.024-.311z" />
            <path d="M84.366 55.61a1.35 1.35 0 01-.214-.019c-7.809-1.298-18.896-2.11-31.22-2.282-.716-.01-1.29-.599-1.28-1.316.011-.717.612-1.291 1.316-1.278 12.45.174 23.676.997 31.61 2.317a1.298 1.298 0 01-.212 2.577M11.714 55.61a1.298 1.298 0 01-.212-2.578c7.972-1.326 19.25-2.15 31.756-2.32.702-.034 1.305.563 1.316 1.28a1.3 1.3 0 01-1.28 1.315c-12.38.167-23.52.98-31.366 2.284a1.35 1.35 0 01-.214.018M94.743 59.56c-.716 0-1.298-.58-1.298-1.298V7.972a1.298 1.298 0 012.595 0v50.29c0 .718-.581 1.298-1.297 1.298M1.337 60.189A1.298 1.298 0 01.04 58.892V8.602a1.297 1.297 0 112.595 0v50.29c0 .716-.582 1.297-1.298 1.297M47.805 85.84a1.298 1.298 0 01-1.297-1.297v-4.457a3.53 3.53 0 013.526-3.525h8.963l-1.426-4.412a1.297 1.297 0 012.469-.798l1.973 6.108a1.294 1.294 0 01-1.234 1.697H50.034a.932.932 0 00-.932.93v4.457c0 .716-.58 1.297-1.297 1.297" />
            <path d="M47.805 85.84a1.298 1.298 0 01-1.297-1.297v-4.457a.931.931 0 00-.93-.93H35.184a1.298 1.298 0 01-1.247-1.655l1.73-6.053a1.297 1.297 0 112.495.712l-1.258 4.4h8.674a3.528 3.528 0 013.524 3.526v4.457c0 .716-.58 1.297-1.297 1.297M49.123 59.911H46.53a1.297 1.297 0 110-2.594h2.594a1.298 1.298 0 010 2.594M47.826 36.56c-4.787 0-6.487 4.193-6.487 7.784 0 3.59 1.7 7.784 6.487 7.784s6.486-4.193 6.486-7.784-1.699-7.784-6.486-7.784zm0 18.162c-5.432 0-9.081-4.17-9.081-10.378s3.65-10.379 9.08-10.379c5.433 0 9.082 4.171 9.082 10.379 0 6.209-3.65 10.378-9.081 10.378z" />
            <path d="M47.826 70.29c-.716 0-1.297-.58-1.297-1.298V58.614a1.298 1.298 0 012.594 0v10.378c0 .718-.58 1.298-1.297 1.298" />
            <path d="M39.116 59.911c-3.496 0-5.866 2.09-7.46 6.578l-4 12.389c-.462 1.46-.4 2.85.164 3.62.378.514.981.765 1.844.765h36.324c.863 0 1.466-.25 1.844-.766.564-.77.626-2.158.162-3.627l-4.01-12.418c-1.582-4.45-3.952-6.54-7.448-6.54h-.198l-6.264 10.231c-.416.78-1.292 1.303-2.248 1.303a2.59 2.59 0 01-2.285-1.365l-6.225-10.17h-.2zm26.872 25.946H29.664c-2.147 0-3.326-.992-3.938-1.826-1.074-1.466-1.272-3.633-.543-5.942l4.015-12.433c1.97-5.545 5.302-8.34 9.918-8.34h.926a1.3 1.3 0 011.107.62L47.79 68.79l6.712-10.852a1.3 1.3 0 011.107-.62h.926c4.616 0 7.949 2.794 9.905 8.302l4.025 12.462c.732 2.317.534 4.484-.54 5.95-.613.834-1.79 1.826-3.938 1.826z" />
          </g>
          <g className="fillPrimary">
            <path d="M26.147 16.976a1.299 1.299 0 01-.092-2.592c13.514-.978 30.747-.98 44.295-.008a1.298 1.298 0 011.2 1.388 1.305 1.305 0 01-1.386 1.2c-13.427-.966-30.53-.962-43.922.008a1.156 1.156 0 01-.095.004M26.147 28.8a1.298 1.298 0 01-.092-2.591c13.52-.98 30.77-.982 44.295-.007a1.297 1.297 0 011.2 1.387 1.305 1.305 0 01-1.386 1.201c-13.401-.966-30.524-.963-43.922.008a2.307 2.307 0 01-.095.003" />
          </g>
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgCommunications;
