import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgEcommerce(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g fill="none" fillRule="evenodd">
          <g className="fillSecondary">
            <path d="M20.844 76.575a1.439 1.439 0 01-1.439-1.439V46.773a5.426 5.426 0 015.42-5.419 5.424 5.424 0 015.419 5.419v21.895a1.439 1.439 0 01-2.876 0V46.773a2.545 2.545 0 00-2.542-2.542 2.545 2.545 0 00-2.543 2.542v28.363c0 .795-.644 1.439-1.439 1.439" />
            <path d="M36.644 70.106a1.439 1.439 0 01-1.44-1.438V55.599c0-1.402-1.112-2.542-2.48-2.542-1.368 0-2.48 1.14-2.48 2.542v13.069a1.439 1.439 0 01-2.876 0V55.599c0-2.988 2.403-5.42 5.356-5.42 2.955 0 5.358 2.432 5.358 5.42v13.069c0 .794-.644 1.438-1.438 1.438" />
            <path d="M44.73 70.106a1.439 1.439 0 01-1.44-1.438v-9.596a2.546 2.546 0 00-2.542-2.543 2.546 2.546 0 00-2.542 2.543v9.596a1.439 1.439 0 01-2.878 0v-9.596a5.426 5.426 0 015.42-5.42 5.424 5.424 0 015.418 5.42v9.596c0 .794-.644 1.438-1.436 1.438" />
            <path d="M52.688 70.106a1.439 1.439 0 01-1.44-1.438v-6.691c0-1.211-1.14-2.196-2.54-2.196-1.403 0-2.544.985-2.544 2.196v6.69a1.437 1.437 0 11-2.876 0v-6.69c0-2.797 2.43-5.073 5.42-5.073 2.987 0 5.419 2.276 5.419 5.073v6.69c0 .795-.645 1.44-1.44 1.44" />
            <path d="M45.476 96.148h-21.89a1.44 1.44 0 01-1.124-.54c-.108-.136-2.737-3.442-7.093-10.982-1.064-1.842-4.789-3.695-7.51-5.049-3.107-1.546-4.82-2.398-5.105-3.828-.207-1.036.036-2.127.685-3.072.929-1.356 2.626-2.337 4.656-2.69 4.2-.737 12.264 3.389 13.169 3.86a1.439 1.439 0 01-1.33 2.551c-3.086-1.608-8.895-4.007-11.345-3.577-1.253.219-2.293.772-2.778 1.482-.168.244-.252.48-.254.705.512.464 2.273 1.342 3.584 1.994 3.386 1.685 7.223 3.594 8.72 6.185 3.261 5.646 5.55 8.884 6.437 10.084h20.507c1.32-1.61 5.4-6.904 6.024-11.488.358-2.612.422-13.018.422-13.124a1.44 1.44 0 011.439-1.43h.009a1.439 1.439 0 011.43 1.447c-.002.436-.066 10.7-.448 13.497-.863 6.317-6.873 13.198-7.126 13.487a1.44 1.44 0 01-1.08.488M16.614 64.576H4.91A4.085 4.085 0 01.83 60.493V4.41C.83 2.16 2.66.328 4.91.328H38.77c2.25 0 4.08 1.83 4.08 4.081v48.153a1.439 1.439 0 01-2.875 0V4.41c0-.664-.541-1.204-1.205-1.204H4.91c-.665 0-1.205.54-1.205 1.204v56.084c0 .665.54 1.206 1.205 1.206h11.703a1.439 1.439 0 010 2.877" />
            <path d="M27.977 10.171H15.702a1.44 1.44 0 010-2.876h12.275a1.439 1.439 0 010 2.876M15.482 56.281H2.675a1.439 1.439 0 110-2.877h12.807a1.439 1.439 0 010 2.877" />
          </g>
          <g className="fillPrimary">
            <path d="M27.202 27.352v.307l2.876-2.302v-.287l-2.876 2.282zm0 9.033H13.796V22.98h12.753l3.088-2.474a1.454 1.454 0 00-.998-.403H12.357a1.45 1.45 0 00-1.44 1.438v16.284a1.45 1.45 0 001.44 1.438h16.282c.788 0 1.439-.652 1.439-1.438v-7.557l-2.876 2.282v3.836z" />
            <path d="M21.2 33.677a1.44 1.44 0 01-1.165-.595l-2.823-3.898a1.438 1.438 0 112.33-1.688l1.945 2.687L31.6 22.235a1.44 1.44 0 011.779 2.263L22.089 33.37c-.256.2-.57.307-.89.307" />
          </g>
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgEcommerce;
