import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgFinancing(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <g className="fillPrimary">
            <path d="M66.281 38.355c-.2 0-.402-.041-.591-.131a1.445 1.445 0 01-.831-1.313v-3.37c-3.014.335-5.586 1.742-7.343 4.049a1.406 1.406 0 01-1.995.258 1.46 1.46 0 01-.255-2.026c2.579-3.383 6.49-5.248 11.015-5.248.785 0 1.422.647 1.422 1.445v1.739l7.501-6.571-7.5-6.57v1.74c0 .797-.638 1.444-1.423 1.444-5.12 0-10.137 2-14.128 5.63-1.183 1.077-2.808 2.956-3.5 3.758-.218.251-.361.413-.4.45-.56.558-1.46.548-2.01-.024a1.46 1.46 0 01-.002-2.017l.274-.315c.727-.842 2.43-2.812 3.74-4.005 4.145-3.77 9.283-5.984 14.604-6.32v-3.494c0-.564.324-1.077.83-1.313.507-.235 1.1-.15 1.521.22l11.1 9.723a1.457 1.457 0 010 2.188l-11.1 9.724a1.41 1.41 0 01-.929.35" />
            <path d="M28.521 38.355c-.334 0-.664-.12-.928-.35l-11.1-9.723A1.456 1.456 0 0116 27.187c0-.42.18-.819.492-1.093l11.1-9.723a1.404 1.404 0 011.522-.22c.505.236.83.749.83 1.313v3.49c8.443.503 15.862 5.467 19.582 13.208a1.452 1.452 0 01-.654 1.931 1.407 1.407 0 01-1.902-.664c-3.449-7.172-10.517-11.628-18.449-11.628a1.434 1.434 0 01-1.422-1.444v-1.74l-7.5 6.57 7.5 6.57V32.02c0-.798.637-1.445 1.422-1.445 4.5 0 8.4 1.926 10.98 5.421a1.46 1.46 0 01-.285 2.022 1.408 1.408 0 01-1.99-.288c-1.786-2.418-4.283-3.848-7.283-4.188v3.37c0 .565-.324 1.078-.829 1.313-.189.09-.392.131-.593.131" />
            <path d="M52.159 28.987a1.433 1.433 0 01-1.423-1.445V12.693c0-.797.638-1.444 1.423-1.444h1.712L47.4 3.632l-6.468 7.617h1.712c.785 0 1.423.647 1.423 1.444v14.85c0 .798-.638 1.444-1.423 1.444a1.432 1.432 0 01-1.422-1.445V14.138h-3.395c-.556 0-1.06-.33-1.293-.842a1.461 1.461 0 01.217-1.546L46.325.478c.54-.637 1.614-.637 2.154 0l9.573 11.272c.363.428.448 1.032.216 1.546a1.422 1.422 0 01-1.292.842H53.58v13.404c0 .8-.637 1.445-1.422 1.445" />
          </g>
          <g className="fillSecondary">
            <path d="M19.772 91.012C9.947 91.012 0 88.377 0 83.342v-7.795a1.434 1.434 0 112.866 0v7.794c0 1.911 6.744 4.799 16.906 4.799 3.684 0 6.445-.233 8.955-.755a1.437 1.437 0 01.585 2.811c-2.711.564-5.653.816-9.54.816M19.772 75.62C9.947 75.62 0 72.985 0 67.948c0-5.035 9.947-7.67 19.772-7.67 3.272 0 6.502.291 9.342.841a1.435 1.435 0 01-.545 2.819c-2.662-.516-5.704-.788-8.797-.788-10.162 0-16.906 2.889-16.906 4.798 0 1.911 6.744 4.8 16.906 4.8 3.168 0 6.273-.285 8.98-.825a1.436 1.436 0 01.559 2.817c-2.888.576-6.186.88-9.54.88" />
            <path d="M19.772 83.413C9.947 83.413 0 80.778 0 75.743v-7.795a1.434 1.434 0 112.866 0v7.794c0 1.912 6.744 4.8 16.906 4.8 3.092 0 6.13-.272 8.789-.786a1.436 1.436 0 01.543 2.819c-2.834.548-6.061.838-9.332.838M74.9 91.012c-3.4 0-6.496-.296-9.467-.905a1.435 1.435 0 01-1.116-1.694 1.425 1.425 0 011.69-1.119c2.78.569 5.69.846 8.893.846 10.163 0 16.906-2.888 16.906-4.799v-7.794c0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436v7.794c0 5.036-9.947 7.67-19.772 7.67M74.9 75.62c-3.234 0-6.43-.284-9.24-.821a1.438 1.438 0 01-1.14-1.68 1.434 1.434 0 011.677-1.142c2.638.504 5.647.772 8.703.772 10.163 0 16.906-2.89 16.906-4.8 0-1.91-6.743-4.8-16.906-4.8-3.287 0-6.185.262-8.86.799a1.43 1.43 0 01-1.687-1.124 1.437 1.437 0 011.123-1.692c2.863-.574 5.945-.854 9.424-.854 9.825 0 19.772 2.635 19.772 7.67 0 5.037-9.947 7.672-19.772 7.672" />
            <path d="M74.9 83.413c-3.255 0-6.468-.287-9.292-.831a1.436 1.436 0 01.542-2.82c2.648.51 5.674.78 8.75.78 10.163 0 16.906-2.888 16.906-4.8v-7.794c0-.792.642-1.436 1.433-1.436.79 0 1.433.644 1.433 1.436v7.794c0 5.036-9.947 7.67-19.772 7.67" />
            <path d="M47.337 87.27c-9.825 0-19.772-2.705-19.772-7.876a1.434 1.434 0 112.866 0c0 2.039 6.585 5.004 16.906 5.004 10.319 0 16.904-2.965 16.904-5.004a1.434 1.434 0 112.866 0c0 5.17-9.945 7.876-19.77 7.876" />
            <path d="M47.337 95.32c-9.825 0-19.772-2.706-19.772-7.877v-8.05a1.434 1.434 0 112.866 0v8.05c0 2.04 6.585 5.005 16.906 5.005 10.319 0 16.904-2.964 16.904-5.005v-8.05a1.434 1.434 0 112.866 0v8.05c0 5.171-9.945 7.876-19.77 7.876" />
            <path d="M47.337 79.42c-9.825 0-19.772-2.706-19.772-7.876 0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436 0 2.04 6.585 5.004 16.906 5.004 10.319 0 16.904-2.964 16.904-5.004a1.434 1.434 0 112.866 0c0 5.17-9.945 7.876-19.77 7.876" />
            <path d="M47.337 87.47c-9.825 0-19.772-2.704-19.772-7.875v-8.051c0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436v8.05c0 2.041 6.585 5.005 16.906 5.005 10.319 0 16.904-2.964 16.904-5.004v-8.051a1.434 1.434 0 112.866 0v8.05c0 5.172-9.945 7.877-19.77 7.877" />
            <path d="M47.337 71.571c-9.825 0-19.772-2.705-19.772-7.877 0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436 0 2.04 6.585 5.005 16.906 5.005 10.319 0 16.904-2.965 16.904-5.005a1.434 1.434 0 112.866 0c0 5.172-9.945 7.877-19.77 7.877" />
            <path d="M47.337 79.62c-9.825 0-19.772-2.705-19.772-7.875v-8.051c0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436v8.05c0 2.041 6.585 5.005 16.906 5.005 10.319 0 16.904-2.964 16.904-5.004v-8.051a1.434 1.434 0 112.866 0v8.05c0 5.171-9.945 7.877-19.77 7.877" />
            <path d="M47.337 63.603c-9.825 0-19.772-2.706-19.772-7.877 0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436 0 2.04 6.585 5.005 16.906 5.005 10.319 0 16.904-2.965 16.904-5.005a1.434 1.434 0 112.866 0c0 5.17-9.945 7.877-19.77 7.877" />
            <path d="M47.337 71.653c-9.825 0-19.772-2.705-19.772-7.876v-8.051c0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436v8.051c0 2.039 6.585 5.004 16.906 5.004 10.319 0 16.904-2.965 16.904-5.004v-8.051a1.434 1.434 0 112.866 0v8.051c0 5.17-9.945 7.876-19.77 7.876" />
            <path d="M47.337 42.872c-10.321 0-16.906 2.963-16.906 5.004 0 2.04 6.585 5.005 16.906 5.005 10.319 0 16.904-2.965 16.904-5.005s-6.585-5.004-16.904-5.004zm0 12.881c-9.825 0-19.772-2.706-19.772-7.877 0-5.17 9.947-7.876 19.772-7.876s19.77 2.705 19.77 7.876c0 5.17-9.945 7.877-19.77 7.877z" />
            <path d="M47.337 63.804c-9.825 0-19.772-2.706-19.772-7.877v-8.051c0-.793.642-1.436 1.433-1.436.79 0 1.433.643 1.433 1.436v8.051c0 2.04 6.585 5.006 16.906 5.006 10.319 0 16.904-2.966 16.904-5.006v-8.051a1.434 1.434 0 112.866 0v8.051c0 5.17-9.945 7.877-19.77 7.877" />
          </g>
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgFinancing;
