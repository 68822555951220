import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgTradeOrganizations(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g fill="none" fillRule="evenodd">
          <g className="fillPrimary">
            <path d="M52.42 95.201a5.532 5.532 0 01-3.893-1.62 5.53 5.53 0 01-1.629-3.94 5.52 5.52 0 011.639-3.932l1.894-1.833a1.392 1.392 0 011.935 2l-1.881 1.818a2.75 2.75 0 00-.804 1.95 2.766 2.766 0 002.809 2.775 2.436 2.436 0 001.767-.81l11.225-10.536c.259-.242.6-.377.953-.377l8.695.001a1.39 1.39 0 110 2.783l-8.144-.002-10.741 10.075c-.884.992-2.22 1.607-3.68 1.647l-.145.001M1.391 57.049a1.392 1.392 0 010-2.783l83.479-.005a1.392 1.392 0 010 2.782L1.39 57.05M24.99 87.622a5.525 5.525 0 01-3.931-1.63 5.528 5.528 0 01-1.63-3.938 5.528 5.528 0 011.64-3.935l14.987-14.925a1.391 1.391 0 111.965 1.971L23.03 80.09a2.763 2.763 0 00-.819 1.968c0 .743.288 1.441.814 1.967 1.05 1.048 2.88 1.048 3.93 0L38.62 72.359a1.392 1.392 0 011.968 1.967L28.922 85.992a5.523 5.523 0 01-3.931 1.63" />
            <path d="M35.519 89.05c-1.356 0-2.708-.637-3.92-1.846a5.528 5.528 0 01-1.629-3.939 5.527 5.527 0 011.638-3.934l7.364-7.323a1.391 1.391 0 111.962 1.973l-7.363 7.323a2.762 2.762 0 00-.818 1.966c0 .741.288 1.441.814 1.965.474.475 1.193 1.033 1.953 1.033h.043c.57-.015 1.163-.365 1.766-1.035l7.297-7.302a1.392 1.392 0 011.967 1.967l-7.246 7.248c-1.08 1.206-2.344 1.865-3.709 1.903l-.12.001" />
            <path d="M43.608 92.303a5.53 5.53 0 01-3.892-1.622 5.521 5.521 0 01-1.629-3.936 5.529 5.529 0 011.636-3.936l4.93-4.865a1.392 1.392 0 011.953 1.98l-4.924 4.86a2.762 2.762 0 00-.812 1.962c0 .743.288 1.443.813 1.968.532.533 1.216.833 1.994.805a2.43 2.43 0 001.767-.808l4.893-4.896a1.392 1.392 0 011.968 1.967l-4.842 4.842c-.914 1.023-2.251 1.638-3.712 1.677a4.923 4.923 0 01-.143.002M53.522 74.408a5.545 5.545 0 01-3.935-1.627l-7.39-7.39h-4.98a1.39 1.39 0 110-2.782h5.556c.37 0 .723.146.984.407l7.798 7.797a2.789 2.789 0 003.936 0 2.789 2.789 0 000-3.936L45.247 56.636a1.392 1.392 0 011.968-1.967l10.243 10.24a5.576 5.576 0 010 7.872 5.548 5.548 0 01-3.936 1.627M20.87 83.478h-9.218a1.39 1.39 0 110-2.782h9.218a1.39 1.39 0 110 2.782" />
          </g>
          <g className="fillSecondary">
            <path d="M62.609 51.478a1.39 1.39 0 01-1.392-1.391V43.13c0-8.868-5.851-15.304-13.913-15.304h-8.347c-8.062 0-13.914 6.436-13.914 15.304v6.957a1.39 1.39 0 11-2.782 0V43.13c0-10.31 7.178-18.087 16.696-18.087h8.347C56.822 25.043 64 32.82 64 43.13v6.957a1.39 1.39 0 01-1.391 1.391M43.13 2.783c-5.133 0-6.956 4.496-6.956 8.347 0 3.852 1.823 8.348 6.956 8.348 5.134 0 6.957-4.496 6.957-8.348 0-3.85-1.823-8.347-6.957-8.347zm0 19.478c-5.825 0-9.739-4.473-9.739-11.13C33.391 4.472 37.305 0 43.131 0c5.825 0 9.739 4.473 9.739 11.13 0 6.658-3.914 11.13-9.74 11.13zM82.087 51.478a1.39 1.39 0 01-1.391-1.391V44.1c0-6.673-5.536-12.1-12.341-12.1h-1.572a1.39 1.39 0 110-2.783h1.572c8.34 0 15.123 6.677 15.123 14.883v5.987a1.39 1.39 0 01-1.391 1.391M65.36 9.74c-4.105 0-5.566 3.593-5.566 6.956 0 3.362 1.461 6.956 5.565 6.956 4.103 0 5.566-3.594 5.566-6.956 0-3.363-1.463-6.957-5.566-6.957zm0 16.695c-4.994 0-8.349-3.914-8.349-9.74 0-5.825 3.355-9.738 8.348-9.738 4.992 0 8.348 3.913 8.348 9.739 0 5.825-3.356 9.739-8.348 9.739zM4.174 51.478a1.39 1.39 0 01-1.391-1.391V44.1c0-8.206 6.784-14.883 15.123-14.883h1.572a1.39 1.39 0 110 2.783h-1.572c-6.805 0-12.34 5.427-12.34 12.1v5.987a1.39 1.39 0 01-1.392 1.391M20.902 9.74c-4.103 0-5.566 3.593-5.566 6.956 0 3.362 1.463 6.956 5.566 6.956 4.104 0 5.565-3.594 5.565-6.956 0-3.363-1.461-6.957-5.565-6.957zm0 16.695c-4.992 0-8.348-3.914-8.348-9.74 0-5.825 3.356-9.738 8.348-9.738 4.993 0 8.347 3.913 8.347 9.739 0 5.825-3.354 9.739-8.347 9.739z" />
            <path d="M75.13 51.478a1.39 1.39 0 01-1.39-1.391v-2.783a1.39 1.39 0 011.39-1.391h1.392a1.39 1.39 0 110 2.783v1.391a1.39 1.39 0 01-1.392 1.391M11.13 51.478a1.39 1.39 0 01-1.39-1.391v-1.391a1.39 1.39 0 110-2.783h1.39c.77 0 1.392.623 1.392 1.391v2.783a1.39 1.39 0 01-1.392 1.391M54.26 51.478a1.39 1.39 0 01-1.39-1.391v-5.565a1.39 1.39 0 011.39-1.392h1.392a1.39 1.39 0 110 2.783v4.174a1.39 1.39 0 01-1.391 1.391M32 51.478a1.39 1.39 0 01-1.391-1.391v-4.174a1.39 1.39 0 110-2.783H32c.77 0 1.391.624 1.391 1.392v5.565A1.39 1.39 0 0132 51.478" />
          </g>
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgTradeOrganizations;
