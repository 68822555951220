import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgWebMarketing(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g fillRule="nonzero">
          <path
            className="fillSecondary"
            d="M3.576 76.8v-7.2h86.51a2.4 2.4 0 012.4 2.4v7.2H5.976a2.4 2.4 0 01-2.4-2.4zm53.958 14.4H38.38v-8.154h19.154V91.2zM91.2 0H4.8C2.15 0 0 2.15 0 4.8v24h3.613V3.638h87.09c1.328 0 1.704.555 1.704 1.88v60.823H5.273c-1.327 0-1.66-.216-1.66-1.541V25.441H0v55.176a2.4 2.4 0 002.4 2.4h32.446V91.2H31.2c-1.327 0-2.153.728-2.153 2.055 0 1.327.826 2.025 2.153 2.025h33.6c1.327 0 2.19-.698 2.19-2.025S66.128 91.2 64.8 91.2h-3.638v-8.183H93.6a2.4 2.4 0 002.4-2.4V4.8C96 2.15 93.85 0 91.2 0z"
          />
          <path
            className="fillPrimary"
            d="M13.804 42.002h12c.78 0 1.577-.255 1.975-.935l4.856-8.854 9.092 18.984c.404.743 1.183 1.226 2.01 1.16a2.207 2.207 0 001.928-1.3l13.494-32.84 9.038 23.785a2.21 2.21 0 002.015 1.324h13.273c1.224 0 2.042-.64 2.042-1.887 0-1.246-.818-1.637-2.042-1.637H70.917l-9.756-24.515a2.208 2.208 0 00-2.002-1.323c-.872 0-1.653.508-2.016 1.303L43.738 47.61 34.53 28.676a2.208 2.208 0 00-1.896-1.16c-.74.015-1.528.404-1.933 1.095l-5.994 10.197h-9.81c-2.443 0-4.425.7-4.425 3.194h3.33z"
          />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgWebMarketing;
