import GeneralTradeConsulting from './GeneralTradeConsulting';
import LegalRegulatory from './legal-regulatory';
import Education from './Education';
import Financing from './Financing';
import MarketResearch from './Worldwide';
import Ecommerce from './Ecommerce';
import WebDigitalMarketing from './WebMarketing';
import Shipping from './Shipping';
import Localization from './Localization';
import Communications from './Communications';
import GovermentResources from './GovermentResources';
import TradeOrganizations from './TradeOrganizations';
import CovidResources from './CovidResources';
import Blank from './Blank';

const CategoryIcon = (slug?: string) => {
  switch (slug) {
    case 'trade-consulting-strategy':
      return GeneralTradeConsulting;
    case 'taxes-legal-regulatory':
      return LegalRegulatory;
    case 'education-and-training':
      return Education;
    case 'market-research':
      return MarketResearch;
    case 'ecommerce':
      return Ecommerce;
    case 'web-digital-marketing':
      return WebDigitalMarketing;
    case 'marketing-communications':
      return Communications;
    case 'financing-insurance':
      return Financing;
    case 'localization':
      return Localization;
    case 'shipping-logistics':
      return Shipping;
    case 'government-resources':
      return GovermentResources;
    case 'trade-organizations':
      return TradeOrganizations;
    case 'covid-19':
      return CovidResources;
    case 'new-hampshire':
      return Blank;
    default:
      return GeneralTradeConsulting;
  }
};

export default CategoryIcon;
