import * as React from 'react';
import IconStyles, { IconProps } from './styles';

function SvgDucument(props: IconProps) {
  return (
    <IconStyles.Container {...props}>
      <svg width={96} height={96} viewBox="0 0 96 96">
        <g fill="none" fillRule="evenodd">
          <g className="fillSecondary">
            <path d="M80 74.483a1.457 1.457 0 01-1.455-1.46V8.762c0-3.222-2.609-5.842-5.818-5.842h-64a1.457 1.457 0 01-1.454-1.46C7.273.653 7.923 0 8.727 0h64c4.813 0 8.728 3.93 8.728 8.763v64.26c0 .805-.65 1.46-1.455 1.46" />
            <path d="M87.273 84.706h-64c-4.813 0-8.728-3.93-8.728-8.763V13.144c0-.806.65-1.46 1.455-1.46.804 0 1.455.654 1.455 1.46v62.8c0 3.22 2.609 5.841 5.818 5.841h64c.804 0 1.454.654 1.454 1.46 0 .807-.65 1.46-1.454 1.46" />
            <path d="M2.91 16.065h11.635V8.763c0-3.222-2.609-5.842-5.818-5.842-3.208 0-5.818 2.62-5.818 5.842v7.302zM16 18.985H1.455A1.457 1.457 0 010 17.526V8.763C0 3.93 3.914 0 8.727 0c4.813 0 8.728 3.93 8.728 8.763v8.762c0 .807-.65 1.46-1.455 1.46zM87.273 84.706c-4.813 0-8.728-3.93-8.728-8.763V56.957c0-.806.65-1.46 1.455-1.46.804 0 1.455.654 1.455 1.46v18.986c0 3.222 2.609 5.842 5.818 5.842 3.208 0 5.818-2.62 5.818-5.842V49.655h-5.818a1.457 1.457 0 01-1.455-1.46c0-.806.65-1.46 1.455-1.46h7.272c.805 0 1.455.654 1.455 1.46v27.748c0 4.833-3.914 8.763-8.727 8.763" />
            <path d="M87.273 77.404a1.457 1.457 0 01-1.455-1.46V33.59H80a1.457 1.457 0 01-1.455-1.46c0-.806.65-1.46 1.455-1.46h7.273c.804 0 1.454.654 1.454 1.46v43.813c0 .806-.65 1.46-1.454 1.46M26.182 13.144v27.748H69.82l-.002-27.748H26.182zm43.636 30.67H26.182a2.918 2.918 0 01-2.91-2.922V13.144c0-1.61 1.305-2.92 2.91-2.92h43.636c1.605 0 2.91 1.31 2.91 2.92v27.748c0 1.611-1.305 2.921-2.91 2.921zM62.545 55.497h-29.09A1.457 1.457 0 0132 54.037c0-.807.65-1.46 1.455-1.46h29.09c.805 0 1.455.653 1.455 1.46 0 .806-.65 1.46-1.455 1.46M71.273 64.26H24.727a1.457 1.457 0 01-1.454-1.46c0-.807.65-1.461 1.454-1.461h46.546c.804 0 1.454.654 1.454 1.46 0 .806-.65 1.46-1.454 1.46M71.273 73.022H24.727a1.457 1.457 0 01-1.454-1.46c0-.806.65-1.46 1.454-1.46h46.546c.804 0 1.454.654 1.454 1.46 0 .806-.65 1.46-1.454 1.46" />
          </g>
          <path
            className="fillPrimary"
            d="M35.056 38.4a2 2 0 01-1.903-1.392l-1.955-5.958h-.4a1.445 1.445 0 01-1.433-1.456c0-.804.64-1.456 1.433-1.456h1.02c.868 0 1.635.562 1.906 1.398l1.27 3.87L38.89 19.55a1.993 1.993 0 011.928-1.478h.015c.911.005 1.684.61 1.923 1.504l2.592 9.656 2.252-6.097a2 2 0 011.82-1.322 1.988 1.988 0 011.894 1.213l3.591 8.212 2.774-9.392A2.007 2.007 0 0159.43 20.4a1.981 1.981 0 011.966 1.12l1.824 3.706h1.98c.794 0 1.434.653 1.434 1.456 0 .804-.64 1.457-1.433 1.457h-2.513a1.989 1.989 0 01-1.796-1.13l-1.091-2.217-2.815 9.54a2 2 0 01-1.8 1.45c-.877.057-1.615-.422-1.96-1.208l-3.682-8.416-2.447 6.63a2.002 2.002 0 01-1.97 1.32 2.01 2.01 0 01-1.846-1.499l-2.481-9.248-3.814 13.562a2.005 2.005 0 01-1.887 1.477l-.044.001z"
          />
        </g>
      </svg>
    </IconStyles.Container>
  );
}

export default SvgDucument;
